/* You can add global styles to this file, and also import other style files */
@import "./mixins";
@import "normalize.css";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "ngx-toastr/toastr.css";

@import "@fortawesome/fontawesome-free/css/all.css";

html,
body {
  height: 100%;
  font-family: Roboto, Arial, sans-serif;
  overscroll-behavior-y: contain;
}

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: lightgreen white;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #fafafa;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgreen;
}

.loading-shade {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.50);
}

.mat-loading .mat-button-wrapper {
  visibility: hidden;
}

.mat-loading .mat-progress-spinner {
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
}
